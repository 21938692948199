export const PREVIEW_TEMPLATE_ID = 'preview';

export const enum CustomHeaderEnum {
    Url = 'x-url',
    Pathname = 'x-pathname',
    BrandColor = 'x-brand-color',
    CompanyName = 'x-company-name',
    Logo = 'x-logo',
}

export const YANDEX_ANALYTICS_ID = '96162840';

export const GOOGLE_TAG_MANAGER_ID = 'GTM-NWMG3VP';

export const isProduction = process.env.NODE_ENV === 'production';
