export const env = {
    ['survey.rdata.one' as string]: {
        NEXT_PUBLIC_AMPLITUDE_API_KEY: 'fed6d12aa033e5dd2ce0535703534db2',
    },
    ['survey.rocketdata.space' as string]: {
        NEXT_PUBLIC_AMPLITUDE_API_KEY: 'c14a6debfbe22db5d13bae9e134c6e88',
    },
};

export const sentryDsn = 'https://97da9d77a4fea51bcc05f999bc7347f5@sentry.rocketdata.io/23';

export const releaseTag = process.env.RELEASE_TAG ?? 'development';
export const sentryEnvironment = process.env.SENTRY_ENV ?? 'staging';
